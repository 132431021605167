<template>
  <div class="contain">
    <div class="contain-card">
      <div class="contain-card-title"><div>律师阅卷未处理数</div><div class="cct-num">{{total}}</div></div>
      <img src="@/assets/home/rob2.gif"  class="contain-card-img" />
    </div>
    <el-table
      v-loading="loading"
      style="width: 100%" stripe
      :data="customerList"
      border
    >
      <!-- <el-table-column type="selection" width="55" align="center" /> -->
      <!--      <el-table-column label="id" align="center" prop="id" />-->
      <!-- <el-table-column label="序号" width="150" align="center" type="index" /> -->
      <!-- <el-table-column
        label="申请人姓名"
        align="center"
        prop="sqrxm"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="当事人"
        align="center"
        prop="dsr"
        :show-overflow-tooltip="true"
      /> -->
      <el-table-column
        label="案件名称"
        align="center"
        prop="ajmc"
        :show-overflow-tooltip="true"
      />
      
      <el-table-column
        label="当事人"
        align="center"
        prop="dsr"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="申请人"
        align="center"
        prop="sqrxm"
        :show-overflow-tooltip="true"
      />
      <!-- <el-table-column
        label="基本单位编码"
        align="center"
        prop="jbdwbm"
        :show-overflow-tooltip="true"
      />
      <el-table-column
        label="证件"
        align="center"
        prop="zj"
        :show-overflow-tooltip="true"
      /> -->
      <el-table-column
        label="申请时间"
        align="center"
        prop="sqsj"
        :show-overflow-tooltip="true"
      />
      <!-- <el-table-column
        label="处理状态"
        align="center"
        prop="clzt"
        :show-overflow-tooltip="true"
      /> -->
      <el-table-column
        label="手机号"
        align="center"
        prop="sqrybm"
        :show-overflow-tooltip="true"
      />
      <!-- <el-table-column
        label="法律文书"
        align="center"
        prop="laweragree"
        :show-overflow-tooltip="true"
      /> -->
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNo"
      :limit.sync="queryParams.pageSize"
      @pagination="getList"
    />
  </div>
</template>

<script>
import cross from "../utils/cross";
import imgUrl from "../../public/avatar.png";
import Vue from "vue";
import qs from "qs";
import axios from "axios";

export default {
  name: "HomeLawyerExamination",
  data() {
    return {
      // 遮罩层
      loading: true,
      customerList: [],
      // 总条数
      total: 0,
      // 查询参数
      queryParams: {
        pageNo: 1,
        pageSize: 10,
        jcyId: "",
        clzt: "0",
      },
    };
  },

  created() {
    this.getList();
    // this.getCookie();
  },

  methods: {
    getList() {
      // 从 localStorage 中读取字符串
      const userString = localStorage.getItem("user");

      // 使用 JSON.parse() 将字符串转换回对象
      const user = JSON.parse(userString);
      this.queryParams.jcyId = user.jcyId;
      this.loading = true;
      let data = JSON.stringify(this.queryParams);
      let url =
        process.env.VUE_APP_BASE_API +
        "/f/userInterface/getCaseMarkingPapers?pageNo=" +
        this.queryParams.pageNo +
        "&pageSize=" +
        this.queryParams.pageSize +
        "&jcyId=" +
        this.queryParams.jcyId +
        "&clzt=" +
        this.queryParams.clzt;

      axios.post(url).then((res) => {
        this.customerList = res.data.list;
        this.total = res.data.count;
        this.loading = false;
      });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.contain-card{
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: calc(100vh * 200 / 1080);
  background-image: url("../assets/home/lsyj_bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: calc(100vw*20/1920);
  margin-top: calc(100vh * 30 / 1080);
  margin-bottom: calc(100vh * 30 / 1080);
  color: #fff;
  font-size: calc(100vw*20/1920);
  padding-left: calc(100vw*62/1920);
    box-sizing: border-box;
  .cct-num{
    font-size: calc(100vw*26/1920);
    margin-top: calc(100vh * 10 / 1080);
  }
  .contain-card-img{
    width: calc(100vw*200/1920);
    margin-bottom: calc(100vh * 58 / 1080);
  }
}
</style>
